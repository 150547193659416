@font-face {
	font-family: 'Lab';
	src: url('/assets/fonts/LabGrotesque-Bold.eot');
	src: url('/assets/fonts/LabGrotesque-Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/LabGrotesque-Bold.woff') format('woff');
	font-weight: bold;
	font-style: bold;
}
@font-face {
	font-family: 'Lab';
	src: url('/assets/fonts/LabGrotesque-Regular.eot');
	src: url('/assets/fonts/LabGrotesque-Regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/LabGrotesque-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
/* Grid */
/* Sections */
/* Desktop styling */
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
div {
	box-sizing: border-box;
}
img {
	box-sizing: border-box;
	width: 100%;
}
video {
	box-sizing: border-box;
	width: 100%;
}
html {
	font-family: "Lab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 19px;
	line-height: 1.5;
	scroll-behavior: smooth;
	padding: 0;
	height: 100%;
}
body {
	font-family: "Lab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 19px;
	line-height: 1.5;
	scroll-behavior: smooth;
	display: grid;
	height: 100%;
	margin: 0 auto;
	max-width: 1700px;
	grid-template-rows: auto 1fr auto auto;
}

h1 {
	font-weight: bold;
	font-family: "Lab";
	font-size: 1em;
}
h2 {
	font-weight: bold;
	font-family: "Lab";
	margin-top: 2rem;
}
h3 {
	font-weight: bold;
	font-family: "Lab";
}
h4 {
	font-weight: bold;
	font-family: "Lab";
}
h5 {
	font-weight: bold;
	font-family: "Lab";
}
p{
	// max-width: 750px;
}
b {
	font-weight: bold;
	font-family: "Lab";
}
a {
	padding-bottom: 2px;
	color: #000;
	text-decoration: none;
	border-bottom: 2px solid #000;
	&:visited {
		color: #000;
		text-decoration: none;
	}
	&:hover {
		color: black;
		border-bottom: 2px solid #1ed760;
	}
	&:active {
		color: black;
		border-bottom: 2px solid #1ed760;
	}
	&:focus {
		color: black;
		border-bottom: 2px solid #1ed760;
	}
}
header a, footer a{
	border-bottom: none;
}
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
li {
	margin-bottom: 5px;
}
.grid-row {
	padding: 0rem 1rem;
	position: relative;
	.column-full {
		width: 100%;
	}
	.column-one-fifth {
		width: 32%;
		display: inline-flex;
	}
}
section {
	padding: 1px 0;
}
.visually-hidden {
	visibility: hidden;
	font-size: 0;
}
header {
	.grid-row {
		padding: 1rem;
	}
	.column-one-third {
		// display: none;
		margin-bottom: 1rem;
	}
}
section.hero {
	font-size: 1.5rem;
	font-weight: bold;
	line-height: 1.45em;
	a {
		border-bottom: 1px solid #000;
		padding-bottom: 0;
		&:hover {
			border-bottom: 2px solid #1ed760;
		}
	}
}
.hero {
	p {
		max-width: 900px;
		margin: 25px 0;
	}
	h1 {
		max-width: 900px;
		margin: 25px 0;
	}
	img.illustration{
		max-width: 325px;
		max-height: 325px;
		padding-top: 35px;
		object-fit: contain;
	}
}
section.featured-projects {
	margin: 1rem 0;
}
.featured-projects {
	.grid-row {
		padding: 0rem;
		margin-bottom: 0;
		flex-wrap: wrap;
	}
}

a.project {
	padding: 1rem;
	margin: 1rem;
	border: 1px solid #000;
	display: block;
	min-height: 200px;
	h3 {
		display: inline-block;
		margin-bottom: 2px;
	}
	&:hover {
		h3 {
			border-bottom: 2px solid #1ed760;
			margin-bottom: 0px;
		}
	}
}

.show-the-thing.space-between{
	// display: block;
	justify-content: space-between;
	div{
		margin: 10px;
	}
}

.show-the-thing{
	div{
		display: flex;
		align-items: flex-start;
		flex: 1;
	}
}

.assessments {
	h3 {
		margin: 0;
	}
	ul {
		list-style: none;
		padding: 0;
	}
	li {
		border-bottom: 1px solid #000;
		padding: .5em 0;
		h3 {
			font-weight: normal;
			color: grey;
		}
		a {
			display: block;
			border-bottom: none;
			&:hover {
				border-bottom: none;
				text-decoration: none;
			}
			&:active {
				border-bottom: none;
				text-decoration: none;
			}
			&:visited {
				border-bottom: none;
				text-decoration: none;
			}
		}
	}
	li.link {
		h3 {
			color: #000;
		}
		&:hover {
			border-bottom: 2px solid #1ed760;
			margin-bottom: 4px;
		}
	}
}
footer {
	.column-one-third {
		margin-top: 1.5rem;
	}
	.column-two-thirds {
		margin-top: 1.5rem;
	}
}
.projects-page {
	.hero {
		.grid-row {
			margin-bottom: 0;
		}
	}
	header {
		.grid-row {
			margin-bottom: 0;
		}
	}
}
.quote {
	margin-left: 0;
	padding: 0 25px;
	max-width: 500px;
	display: block;
	margin: auto;
	object-fit: cover;
	p.content {
		margin: 0;
		font-size: 2em;
	}
	.attribution {
		a {
			color: black;
			font-size: 1em;
		}
	}
}
@media (min-width: 48rem) {
	.supporting-image{
		padding: 0;
	}
}
.supporting-image {

	img {
		display: block;
		margin: auto;
		max-height: 400px;
		max-width: 100%;
		padding: 0;
		display: block;
		margin: auto;
		object-fit: cover;
	}

	.imageWrapper {
		padding-top: 56.25%;
		height: 0px;
		position: relative;
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	video {
		display: block;
		margin: auto;
		max-height: 400px;
		max-width: 600px;
	}

	.youtube-video-container {
    padding-top: 56.25%;
    height: 0px;
    position: relative;
	}

	.youtube-video {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	}
}

a.link {
	border-bottom: 2px solid #000;
	&:hover {
		border-bottom: 2px solid #1ed760;
	}
}
.cover {
	object-fit: cover;
}

#post main{
	ul{
		list-style: disc;
		padding: 0 0 0 1rem;
		margin: 0;
	}
	.assessments ul {
		list-style: none;
		padding: 0;
		margin-top: 1rem;
	}
}

header{
	.padding-left{
		padding-left: 0;
	}
}
@media (min-width: 48rem) {
	h2 {
		margin-top: -10px;
	}
	.grid-row {
		padding: 0 2rem 2rem 2rem;
		display: flex;
		justify-content: space-between;
		.column-one-whole {
			width: 100%;
		}
		.column-one-half {
			width: 50%;
		}
		.column-one-third {
			width: 33.33%;
		}
		.column-two-thirds {
			width: 66.66%;
		}
		.column-one-quarter {
			width: 25%;
		}
		.column-one-fifth {
			width: 20%;
		}
	}
	.grid-row.no-flex {
		display: block;
	}
	.visually-hidden {
		position: absolute;
		left: -9999em;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}
	.visuallyhidden {
		position: absolute;
		left: -9999em;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}
	header {
		.column-one-third {
			display: block;
		}
		.padding-left{
			padding-left: 1rem;
		}
		.grid-row {
			padding: 1rem 2rem;
		}
	}
	section.hero {
		p {
			font-size: 1.75rem;
			font-weight: bold;
			line-height: 1.45em;
		}
		a {
			&:hover {
				border: none;
				border-bottom: 3px solid #1ed760;
			}
		}
	}
	.hero {
		h1 {
			font-size: 1.75rem;
			font-weight: bold;
			line-height: 1.45em;
			padding-right: 1rem;
		}
	}
	.featured-projects {
		.grid-row {
			padding: 0 1rem 1rem 1rem;
		}
	}

	.show-the-thing{
		display: flex;
		div{
			align-items: stretch;
		}
		img{
			object-fit: cover;
		}
	}
	.assessments {
		ul {
			padding: 2em 0 4em 0;
		}
	}
	footer {
		display: none;
	}
	a.toggle {
		color: #fff;
		text-decoration: underline;
		margin-bottom: 1rem;
		display: inline-block;
	}
	#post main{
		.grid-row {
			margin-bottom: 1rem;
		}
		.column-one-half, .column-two-thirds {
			&:first-child {
				padding-bottom: 1.5rem;
				border-bottom: 1px solid #ededed;
				margin-right: 1rem;
			}
			&.no-border{
				padding-bottom: 0;
				border-bottom: none;
				margin-right: 1rem;
			}
		}
		ul{
			list-style: disc;
			padding: 0 0 0 1rem;
			margin: 0;
		}
	}
	.assessments ul {
		list-style: none;
		padding: 0;
	}
}
